.home {
  background-color: #250836;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 3930px;
}

.home .div {
  background-color: #250836;
  position: relative;
  width: 1440px;
}

.home .p {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    opacity: 0.8;
    position: absolute;
    top: 375px;
    width: 391px;
  }
