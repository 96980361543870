
// .home .header {
//   background-color: transparent;
//   height: 47px;
//   left: 80px;
//   // position: absolut;
//   top: 39px;
//   width: 1280px;
// }
.home .header a{
    text-decoration: none;
}
.home .group-3 {
  height: 45px;
  left: 1022px;
  position: absolute;
  top: 0;
  width: 262px;
}
.home .navbar {
  height: 24px;
  left: 258px;
  position: absolute;
  top: 10px;
  width: 646px;
}
.home .group-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 118px;
}

.home .overlap-6 {
  border-radius: 12px;
  height: 45px;
  left: 148px;
  position: absolute;
  top: 0;
  width: 110px;
}
.home .overlap-group-8 {
    background-color: #28003e;
    border: 1px solid;
    border-color: #c605c3;
    border-radius: 12px;
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 131px;
  }
.home .rectangle-9 {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 12px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 110px;
}

.home .text-wrapper-33 {
  color: var(--color-styles-unnamed-color);
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 16px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}


.home .text-wrapper-34 {
  color: var(--color-styles-unnamed-color);
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 13px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}




.home .text-wrapper-35 {
    color: #ffffff;
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 23.7px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home .text-wrapper-36 {
    color: var(--unnamed-color);
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 309px;
    letter-spacing: 0;
    line-height: 23.7px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home .text-wrapper-37 {
    // color: #ffffff6e;
    color : var(--unnamed-color);
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 473px;
    letter-spacing: 0;
    line-height: 23.7px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home .text-wrapper-38 {
    color: var(--unnamed-color);
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 560px;
    letter-spacing: 0;
    line-height: 23.7px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home .text-wrapper-39 {
    color: var(--unnamed-color);
    font-family: "Helvetica-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 137px;
    letter-spacing: 0;
    line-height: 23.7px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  