.login {
    background-color: #250836;
    // display: flex;
    // flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .login a{
   text-decoration: none;
  }
  .login .Link{
    text-decoration: none;
  }
  .login .div {
    background-color: #250836;
    height: 768px;
    position: relative;
    width: 1440px;
  }
  
  .login .card {
    height: 524px;
    left: 820px;
    position: absolute;
    top: 123px;
    width: 505px;
  }
  
  .login .overlap-group {
    height: 519px;
    position: relative;
    top: 2px;
    width: 501px;
  }
  
  .login .ellipse {
    background-color: #ffd8599e;
    border-radius: 196.5px/186px;
    filter: blur(800px);
    height: 372px;
    left: 93px;
    position: absolute;
    top: 142px;
    width: 393px;
  }
  
  .login .image {
    height: 461px;
    left: 136px;
    position: absolute;
    top: 58px;
    width: 365px;
  }
  
  .login .img {
    height: 416px;
    left: 0;
    position: absolute;
    top: 46px;
    width: 289px;
  }
  
  .login .image-2 {
    height: 491px;
    left: 53px;
    position: absolute;
    top: 0;
    width: 366px;
  }
  
  .login .login-input {
    height: 485px;
    left: 188px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 167px;
    width: 364px;
    
  }
  
  .login .group-2 {
    height: 120px;
    left: 0;
    top: 365px;
    width: 364px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .login .group-wrapper {
    border: 1px solid;
    border-color: #f0edff;
    display: flex;
    border-radius: 16px;
    height: 52px;
    left: 0;
    top: 0;
    width: 364px;
  }
  
  .login .div-wrapper {
    border: 1px solid;
    border-color: #f0edff;
    border-radius: 16px;
    height: 52px;
    left: 0;
    top: 68px;
    width: 364px;
  }
  
  
  .login .p {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: normal;
    // position: absolute;
    top: 6px;
    white-space: nowrap;
  }
  
  .login .text-wrapper {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .login .span {
    font-weight: 700;
  }
  
  .login .img-2 {
    height: 30px;
    left: 0;
    object-fit: cover;
    // position: absolute;
    top: 0;
    width: 30px;
  }
  
  
  .login .group-4 {
    height: 30px;
    left: 100px;
    position: relative;
    top: 10px;
    width: 172px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .login .overlap-group-wrapper {
    display: flex; /* Sử dụng Flexbox để quản lý bố cục */
    flex-direction: column; /* Sắp xếp nội dung theo chiều dọc */
    align-items: center; /* Căn giữa nội dung theo chiều ngang */
    justify-content: center; /* Căn giữa nội dung theo chiều dọc */
    height: auto; /* Để chiều cao tự động mở rộng theo nội dung */
    // width: 100%;
  }
  
  .login .overlap-group-2 {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    gap: 10px; 
    height: auto; 
    width: 100%; 
  }
  
  .login .ng-nh-p-kh-c {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 120px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    white-space: nowrap;
  }
  
  .login .text-wrapper-2 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .login .subtract {
    height: 1px;
    left: 0;
  
    top: 9px;
    width: 364px;
  }
  
  .login .group-5 {
    height: 248px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 366px;
  }
  
  .login .b-n-kh-ng-c-t-i-kho {
    color: #fffffd;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 80px;
    letter-spacing: 0;
    line-height: normal;
    top: 234px;
    white-space: nowrap;
  }
  
  .login .text-wrapper-3 {
    color: #fffffd;
    font-family: "Roboto", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .login .group-6 {
    height: 52px;
    left: 59px;
    top: 168px;
    width: 249px;
  }
  
  .login .overlap-group-3 {
    background: linear-gradient(
      180deg,
      rgb(226.58, 186.79, 255) 0%,
      rgb(140.74, 35.37, 226.95) 100%
    );
    border-radius: 16px;
    box-shadow: 0px 8px 21px #00000029;
    height: 52px;
    width: 247px;
    display: flex; /* Kích hoạt Flexbox */
    justify-content: center; /* Căn giữa theo chiều ngang */
    align-items: center; /* Căn giữa theo chiều dọc */
    cursor: pointer; /* Thêm con trỏ khi hover */
  }
  
  .login .text-wrapper-4 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
  }
  
  
  .login .group-7 {
    height: 17px;
    left: 0;
    display: flex;
    justify-content: space-between;
    top: 135px;
    width: 366px;
  
  }
  
  .login .text-wrapper-5 {
    color: #fffffd;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 259px;
    letter-spacing: 0;
    line-height: normal;
    top: 0;
    white-space: nowrap;
  }
  
  .login .remember-me {
    height: 17px;
    left: 0;
    display: flex;
    top: 0;
    width: 130px;
    gap: 5px;
  }
  
  .login .text-wrapper-6 {
    color: #fffffd;
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
  
    top: 0;
    white-space: nowrap;
  }
  /* Ẩn checkbox mặc định */
  .checkbox-input {
    display: none;
  }
  
  /* Tùy chỉnh giao diện checkbox */
  .rectangle {
    border: 0.5px solid #ffffff;
    border-radius: 2px;
    height: 13px;
    width: 13px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent; /* Nền mặc định */
  }
  
  /* Giao diện khi checkbox được chọn */
  .checkbox-input:checked + .rectangle {
    background-color: #0084ff; /* Màu nền khi chọn */
    border-color: #000000; /* Thay đổi màu viền khi chọn */
  }
  
  .checkbox-input:checked + .rectangle::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    width: 3px;
    height: 7px;
    border: solid #c8ff00; /* Màu dấu check */
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
  
  .login .input {
    display: flex;
    flex-direction: column; /* Sắp xếp các thành phần theo chiều dọc */
    gap: 10px; /* Khoảng cách giữa các thành phần */
    width: 364px; /* Chiều rộng container */
  }
  
  .login .user-name,
  .login .password {
    height: 52px;
    width: 100%; /* Đảm bảo các thành phần bên trong đầy chiều rộng của container */
  }
  
  .login .overlap-group-4 {
    background-color: #efedffcc;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    position: relative; /* Để hỗ trợ icon và label */
  }
  
  .login .frame {
    height: 24px;
    left: 18px;
    position: absolute;
    top: 14px;
    width: 24px;
  }
  
  .input-field {
    width: calc(100% - 48px); /* Trừ khoảng cách với icon */
    padding: 18px 48px; /* Cách đều từ label và icon */
    font-size: 16px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    border: none;
    background: transparent;
    outline: none;
    position: relative;
    z-index: 1; /* Nằm trên label */
  }
  
  .overlap-group-4 {
    position: relative;
  }
  
  .text-wrapper-8,
  .text-wrapper-7 {
    top: 18px;
    left: 48px;
    font-size: 14px;
    color: #1c1c1c;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    transition: 0.3s ease all; /* Hiệu ứng mượt mà */
    z-index: 0; /* Nằm dưới input */
    pointer-events: none; /* Không tương tác được */
    opacity: 1; /* Hiển thị ban đầu */
  }
  
  .input-field:focus + .frame + .text-wrapper-8,
  .input-field:focus + .frame + .text-wrapper-7,
  .input-field:not(:placeholder-shown) + .frame + .text-wrapper-8,
  .input-field:not(:placeholder-shown) + .frame + .text-wrapper-7 {
    opacity: 0; /* Biến mất */
    visibility: hidden; /* Ẩn hoàn toàn khỏi luồng tài liệu */
  }
  
  .login .text-wrapper-9 {
    color: #ffffff;
    font-family: "Roboto", Helvetica;
    font-size: 30px;
    font-weight: 700;
    left: 97px;
    letter-spacing: 0;
    line-height: normal;
  
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .login .logo {
    height: 44px;
    left: 80px;
    position: absolute;
    top: 33px;
    width: 118px;
  }
  .login .input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .container{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
   .span{
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  .error-message{
    font-size: medium;
    font-weight: bold;
    color: red;
  }