.home .social-icons-instance {
  height: 39px !important;
  left: 65px !important;
  width: 39px !important;
}

.home .blog-div {
  height: 498px;
  left: 80px;
  position: absolute;
  top: 3037px;
  width: 1280px;
}

.home .blog {
  height: 498px;
  left: 864px;
  position: absolute;
  top: 0;
  width: 422px;
}

.home .watch-detail {
  height: 45px;
  left: 0;
  position: absolute;
  top: 453px;
  width: 168px;
}

.home .overlap-group-2 {
  border-radius: 15px;
  height: 45px;
  position: relative;
  width: 166px;
}

.home .rectangle {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 15px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 166px;
}

.home .text-wrapper-9 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 21px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}


.home .text-wrapper-10 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  opacity: 0.8;
  position: absolute;
  top: 333px;
  width: 367px;
}

.home .text-wrapper-11 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 295px;
  width: 135px;
}

.home .rectangle-2 {
  height: 287px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 416px;
}

.home .blog-2 {
  height: 498px;
  left: 432px;
  position: absolute;
  top: 0;
  width: 422px;
}

.home .blog-introduction {
  height: 298px;
  left: 0;
  position: absolute;
  top: 0;
  width: 392px;
}

.home .watch-more {
  height: 45px;
  left: 0;
  position: absolute;
  top: 253px;
  width: 163px;
}

.home .overlap-group-3 {
  border-radius: 12px;
  height: 45px;
  position: relative;
  width: 161px;
}

.home .rectangle-3 {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 12px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 161px;
}

.home .text-wrapper-12 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.home .text-wrapper-13 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 84px;
  width: 388px;
}

.home .text-wrapper-14 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 0;
  width: 136px;
}

.home .step-to-connect {
  height: 407px;
  left: 80px;
  position: absolute;
  top: 2550px;
  width: 1280px;
}

.home .step-div {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 632px;
}

.home .overlap-group-4 {
  height: 187px;
  position: relative;
}

.home .rectangle-4 {
  background-color: #14001f;
  border-radius: 33px;
  height: 176px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 632px;
}

.home .rectangle-5 {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 70px;
  left: 550px;
  position: absolute;
  top: 0;
  width: 82px;
}

.home .step {
  height: 110px;
  left: 92px;
  position: absolute;
  top: 44px;
  width: 472px;
}

.home .text-wrapper-15 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 24px;
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 31.2px;
  position: absolute;
  top: 0;
  width: 381px;
}

.home .text-wrapper-16 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 50px;
  width: 468px;
}

.home .overlap-wrapper {
  height: 191px;
  left: 0;
  position: absolute;
  top: 216px;
  width: 632px;
}

.home .overlap {
  height: 191px;
  position: relative;
}

.home .rectangle-6 {
  background-color: #14001f;
  border-radius: 33px;
  height: 176px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 632px;
}

.home .step-2 {
  height: 110px;
  left: 92px;
  position: absolute;
  top: 48px;
  width: 445px;
}

.home .text-wrapper-17 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 50px;
  width: 441px;
}

.home .overlap-group-wrapper {
  height: 187px;
  left: 648px;
  position: absolute;
  top: 0;
  width: 632px;
}

.home .step-3 {
  height: 127px;
  left: 92px;
  position: absolute;
  top: 35px;
  width: 483px;
}

.home .text-wrapper-18 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 37px;
  width: 479px;
}

.home .div-wrapper {
  height: 191px;
  left: 648px;
  position: absolute;
  top: 216px;
  width: 632px;
}

.home .step-4 {
  height: 131px;
  left: 92px;
  position: absolute;
  top: 37px;
  width: 483px;
}

.home .text-wrapper-19 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 41px;
  width: 479px;
}

.home .text-wrapper-20 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 900;
  left: 228px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  text-align: center;
  top: 2455px;
  width: 984px;
}

.home .image {
  height: 463px;
  left: 0;
  position: absolute;
  top: 1905px;
  width: 1439px;
}

.home .tarot-reader-service {
  height: 394px;
  left: 80px;
  position: absolute;
  top: 1424px;
  width: 1281px;
}

.home .tarot-reader {
  height: 394px;
  left: 648px;
  position: absolute;
  top: 0;
  width: 637px;
}

.home .xem-ngat-btn {
  height: 45px;
  left: 1px;
  position: absolute;
  top: 349px;
  width: 163px;
}

.home .text-wrapper-21 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.home .text-wrapper-22 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 92px;
  width: 632px;
}

.home .text-wrapper-23 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 900;
  left: 1px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 0;
  width: 632px;
}

.home .image-2 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 540px;
}

.home .card-service {
  height: 340px;
  left: 80px;
  position: absolute;
  top: 921px;
  width: 1353px;
}

.home .overlap-2 {
  height: 340px;
  position: relative;
}

.home .trai-bai-service {
  height: 340px;
  left: 648px;
  position: absolute;
  top: 0;
  width: 705px;
}

.home .overlap-3 {
  height: 340px;
  position: relative;
  width: 709px;
}

.home .div-2 {
  background-color: #14001f;
  border-radius: 30px;
  height: 340px;
  left: 0;
  position: absolute;
  top: 0;
  width: 632px;
}

.home .div-3 {
  height: 286px;
  left: 324px;
  position: absolute;
  top: 34px;
  width: 385px;
}

.home .trai-bai-btn {
  height: 45px;
  left: 0;
  position: absolute;
  top: 241px;
  width: 242px;
}

.home .overlap-group-5 {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 12px;
  height: 45px;
  position: relative;
  width: 240px;
}

.home .text-wrapper-24 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 900;
  left: 17px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.home .text-wrapper-25 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 46px;
  width: 294px;
}

.home .image-3 {
  height: 340px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 308px;
}

.home .boi-bai-service {
  height: 340px;
  left: 0;
  position: absolute;
  top: 0;
  width: 705px;
}

.home .boi-bai-btn {
  height: 45px;
  left: 0;
  position: absolute;
  top: 241px;
  width: 163px;
}

.home .text-wrapper-26 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 900;
  left: 19px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.home .text-wrapper-27 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 46px;
  width: 280px;
}

.home .text-wrapper-28 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 900;
  left: 317px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  text-align: center;
  top: 818px;
  width: 806px;
}

.home .body {
  height: 557px;
  left: 80px;
  position: absolute;
  top: 154px;
  width: 1303px;
}

.home .card {
  height: 545px;
  left: 758px;
  position: absolute;
  top: 0;
  width: 545px;
}

.home .overlap-4 {
  height: 545px;
  position: relative;
}

.home .card-background {
  background-color: #ffd8599e;
  border-radius: 248.5px;
  filter: blur(800px);
  height: 497px;
  left: 48px;
  position: absolute;
  top: 0;
  width: 497px;
}

.home .card-2 {
  height: 524px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 505px;
}

.home .overlap-group-6 {
  height: 519px;
  position: relative;
  top: 2px;
  width: 501px;
}

.home .image-4 {
  height: 461px;
  left: 136px;
  position: absolute;
  top: 58px;
  width: 365px;
}

.home .image-5 {
  height: 416px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 289px;
}

.home .image-6 {
  height: 491px;
  left: 53px;
  position: absolute;
  top: 0;
  width: 366px;
}

.home .service-button {
  height: 45px;
  left: 0;
  position: absolute;
  top: 512px;
  width: 416px;
}

.home .trai-bai-btn-2 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 183px;
}

.home .overlap-group-7 {
  border-radius: 12px;
  height: 45px;
  position: relative;
  width: 181px;
}

.home .rectangle-7 {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 12px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 181px;
}

.home .text-wrapper-29 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 14px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.home .dang-ky-button {
  height: 45px;
  left: 201px;
  position: absolute;
  top: 0;
  width: 217px;
}

.home .overlap-5 {
  border-radius: 12px;
  height: 45px;
  position: relative;
  width: 215px;
}

.home .rectangle-8 {
  background-color: #28003e;
  border: 1px solid;
  border-color: #c605c3;
  border-radius: 12px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 215px;
}

.home .text-wrapper-30 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.home .intro {
  height: 378px;
  left: 4px;
  position: absolute;
  top: 80px;
  width: 632px;
}

.home .text-wrapper-31 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 900;
  left: 3px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 0;
  width: 517px;
}

.home .text-wrapper-32 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 168px;
  width: 628px;
}
