.div {
  padding-top: 100px;

  .introduction {
    padding-top: 100px;

    .image-container {
      padding-top: 100px;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .tarot-image {
        width: 100%;
        height: auto;
        display: block;
      }

      .overlay-text {
        position: absolute;
        color: white;
        font-size: 24px;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
