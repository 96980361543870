.home .footer {
  background-color: transparent;
  height: 294px;
  left: 77px;
  position: absolute;
  top: 3597px;
  width: 1283px;
}

.home .contact {
  height: 176px;
  left: 866px;
  position: absolute;
  top: 62px;
  width: 323px;
}
.home .vector {
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 19px;
}
.home .group {
  height: 39px;
  left: 0;
  position: absolute;
  top: 137px;
  width: 181px;
}

.home .img {
  height: 32px;
  left: 150px;
  position: absolute;
  top: 3px;
  width: 31px;
}
.home .general-information {
  height: 183px;
  left: 543px;
  position: absolute;
  top: 62px;
  width: 241px;
}

.home .website-name {
  height: 244px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 272px;
}

.home .line {
  height: 1px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 1280px;
}
.home .text-wrapper {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 1px;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 54px;
  width: 316px;
}

.home .text-wrapper-2 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 1px;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 87px;
  width: 316px;
}

.home .text-wrapper-3 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}
.home .text-wrapper-4 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}
.home .text-wrapper-5 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 54px;
  width: 231px;
}

.home .overlap-group {
  height: 59px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 231px;
}

.home .text-wrapper-6 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 231px;
}

.home .text-wrapper-7 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 29px;
  width: 231px;
}

.home .text-wrapper-8 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 153px;
  width: 231px;
}

.home .element-soulreader {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 3px;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 214px;
  white-space: nowrap;
}



.home .group-2 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 270px;
  }